<template>
  <div>
    <b-card title="Vous pouvez rechercher les devis directement ici.">
      <b-row>
        <b-col
          cols="12 mb-4"
        >
          <div class="px-2 py-1 navbar-light header-navbar rounded-lg shadow d-flex align-items-center justify-content-start">
            <feather-icon
              icon="SearchIcon"
              class="mr-50 text-primary"
              size="20"
            />
            <b-input-group class="input-group-merge shadow-none">
              <b-form-input
                v-model="code"
                placeholder="Rechercher un devis..."
                style="height: auto;"
                class="border-0 shadow-none"
                @input="filter"
              />
            </b-input-group>
          </div>
        </b-col>
      </b-row>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <b-row v-else class="match-height">
        <b-table
          ref="selectableTable"
          selectable
          :items="quotations"
          :fields="fields"
        >
          <!-- <template #cell(show_details)="data">
            <b-form-checkbox
              v-model="data.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="data.toggleDetails"
            >
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                </span>
              </span>
              <span class="vs-label">{{ data.detailsShowing ? 'Cacher' : 'Voir' }}</span>
            </b-form-checkbox>
          </template> -->

          <template #cell(_createdAt)="data">
            {{ $moment(data.item._createdAt).format('DD/MM/YYYY') }}
          </template>
          <!-- <template #cell(price)="data">
            <p>{{ data.item.price }}€</p>
          </template> -->
          <template #cell(url)="data">
            <a :href="data.item.url">Télécharger</a>
          </template>
          <!-- <template #cell(status)="data">
            {{ data }}
          </template> -->
        </b-table>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BSpinner,
  BFormInput,
  BTable,
  VBPopover,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormInput,
    BCard,
    BSpinner,
    BRow,
    BCol,
    BTable,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      required,
      baseQuotations: [],
      quotations: [],
      products: [],
      categories: [],
      quotation: {},
      conditionalProducts: '',
      conditionalCategories: '',
      types: [{ name: 'Remise fixe', type: 'fixed_cart' }, { name: 'Remise en pourcentage', type: 'percent' }],
      individualType: [{ name: 'Oui', type: true }, { name: 'Non', type: false }],
      conditions: [{ name: 'OU', type: 'or' }, { name: 'ET', type: 'and' }],
      code: '',
      fields: [{ key: 'number', sortable: true }, { label: 'Date', key: '_createdAt', sortable: true }, { label: 'Prénom', key: 'order.form.shipping.first_name', sortable: true }, { label: 'Nom', key: 'order.form.shipping.last_name', sortable: true }, { label: 'Email', key: 'order.form.shipping.email', sortable: true }, { label: 'Total', key: 'order.price' }, { label: 'Devis', key: 'url' }],
      showModal: false,
      categoryInformations: [],
      loading: false,
    }
  },
  async mounted() {
    try {
      await this.getQuotations()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async getQuotations() {
      try {
        const {
          devis,
        } = await this.$http.get('/admin/devis')
        this.quotations = devis
        this.baseQuotations = devis
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    filter() {
      this.filterQuery = this.code
      if (!this.code || this.code === '') {
        this.quotations = this.baseQuotations
      } else {
        const value = this.code
        console.log(value)
        this.quotations = this.baseQuotations.filter(quotation => quotation.sku?.toLowerCase().includes(value.toLowerCase()))
      }
    },
  },
}
</script>

<style>
 .jsoneditor-poweredBy {
   display: none;
 }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
